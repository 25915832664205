import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Container, Row, Col, CarouselItem, Button, CardDeck, Card, CardTitle, CardText } from "reactstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";

import banner from "../images/practice management-01-crop-u401002.png";

import logo from "../images/drive_revenue_svg-04.svg";

import play from "../images/drive_revenue_svg-05.svg";

import how1 from "../images/small icons-01.png";
import how2 from "../images/small icons-02.png";
import how3 from "../images/small icons-03.png";
import how4 from "../images/small icons-04.png";
import how5 from "../images/small icons-05.png";
import how6 from "../images/small icons-06.png";
import { Link } from "gatsby";
import RequestADemo from "../components/request-a-demo";

const prices = [
    {
        title: "Monthly",
        price: 750,
    },
    {
        title: "Quarterly",
        price: 675,
    },
    {
        title: "Bi-annual",
        price: 637,
    },
    {
        title: "Annual",
        price: 600,
    },
];

const OurSolutions = () => {
    const [isOpen, setIsOpen] = useState(false);

    const onPlay = () => {
        setIsOpen(true);
    }

    const onClose = () => {
        setIsOpen(false);
    }

    const priceCards = prices.map(x =>
        <Card key={x.title} body style={{ background: "linear-gradient(to bottom, #3750C2, #151445 100%)" }}>
            <CardTitle tag="h3" className="text-secondary text-center pt-4 pb-3">
                {x.title}
            </CardTitle>
            <CardText className="text-center d-flex flex-column align-items-center pb-4">
                <div className="d-flex pb-5">
                    <small className="text-secondary align-self-start pr-2 pt-2 text-bold">
                        R
                    </small>
                    <span className="text-white display-4">
                        {x.price}
                    </span>
                    <small className="text-white h4">
                        *
                    </small>
                    <small className="text-secondary align-self-end">
                        / mo
                    </small>
                </div>

                <p className="text-white">
                    Per user subscription, billed monthly in advance
                </p>
            </CardText>

            <Button className="rounded-pill pl-3 pr-3" tag={Link} to="/contact-us">
                Find out more
            </Button>
        </Card>
    );

    return (
        <Layout>
            <SEO title="Our Solutions" />

            <Container fluid>
                <Row>
                    <Col className="top-carousel pl-0 pr-0">
                        <CarouselItem
                            className="d-flex align-items-center top-carousel"
                        >
                            <div className="carousel-caption d-none d-md-block position-relative text-left mb-4 pb-4" style={{ left: "10%" }}>
                                <h1 className="display-3">
                                    Total end-to-end, cloud based, practice management solutions
                                </h1>
                                <p className="pt-4 pb-4 mt-4 mb-4 h5">
                                    Designed by business experts and lawyers, Drive Practice is specially built for law firms and practices – of all sizes, and for all fields of specialization.
                                </p>
                                <RequestADemo>
                                    {onClick =>
                                        <Button outline color="light text-uppercase mb-4 rounded-pill pr-5 pl-5 pt-3 pb-3" size="lg" onClick={onClick}>
                                            Request a Demo
                                        </Button>
                                    }
                                </RequestADemo>
                            </div>

                            <img src={banner} style={{ height: "70vh" }} className="" />

                        </CarouselItem>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row style={{ background: "#151445", minHeight: "80vh" }}>
                    <Col className="offset-1 align-self-center">
                        <Row>
                            <Col md={9}>
                                <img src={logo} style={{ width: 343, height: 102 }} className="d-block mb-5" />

                                <p className="lead text-primary">
                                    Designed by business experts and lawyers, Drive Practice is specially built for law firms and practices – of all sizes, and for all fields of specialization.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} className="pr-0">
                        <ModalVideo channel="youtube" isOpen={isOpen} videoId="WXv7rgs80pU" onClose={onClose} />
                        <Button style={{ width: "100%", height: "100%", background: "#3750C2", outline: "none", border: "none", borderRadius: 0 }} onClick={onPlay}>
                            <img src={play} />
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container className="pt-5 mt-5 mb-5 pb-5">
                <Row className="pt-5">
                    <Col>
                        <h1 className="display-3 text-center text-secondary mb-5">
                            How does Drive Revenue add value to your practice?
                        </h1>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <div className="d-flex align-items-center pb-4 mt-5">
                            <img src={how1} />

                            <h2 className="text-secondary h4 font-weight-bold pl-3">
                                REAL <br />FLEXIBILITY
                            </h2>
                        </div>
                        <p className="mb-5">
                            Based in the Cloud, Drive Practice connects you with your practice management and accounting systems in real-time – wherever you are and on any device.
                        </p>
                    </Col>
                    <Col md={4}>
                        <div className="d-flex align-items-center pb-4 mt-5">
                            <img src={how2} />

                            <h2 className="text-secondary h4 font-weight-bold pl-3">
                                REAL <br />SOLUTIONS
                            </h2>
                        </div>
                        <p className="mb-5">
                            Drive Practice brings your legal practice together in one place - Allowing you to manage your clients and matters, reports, monthly and ad-hoc billings.
                        </p>
                    </Col>
                    <Col md={4}>
                        <div className="d-flex align-items-center pb-4 mt-5">
                            <img src={how3} />

                            <h2 className="text-secondary h4 font-weight-bold pl-3">
                                REAL SECURITY <br />&amp; SCALABILITY
                            </h2>
                        </div>
                        <p className="mb-5">
                            Drive Revenue’s safe and secure cloud platform brings you cost savings, flexibility and scalability while ensuring compliance – delivering complete peace of mind.
                        </p>
                    </Col>
                    <Col md={4}>
                        <div className="d-flex align-items-center pb-4 mt-5">
                            <img src={how4} />

                            <h2 className="text-secondary h4 font-weight-bold pl-3">
                                REAL <br />VALUE
                            </h2>
                        </div>
                        <p className="mb-5">
                            Drive Practice has focused your critical information and workflows into one powerful, convenient platform – enabling you to decrease tedious admin time, increase your efficiency, and Driving Revenue in your business.
                        </p>
                    </Col>
                    <Col md={4}>
                        <div className="d-flex align-items-center pb-4 mt-5">
                            <img src={how5} />

                            <h2 className="text-secondary h4 font-weight-bold pl-3">
                                REAL <br />TIME
                            </h2>
                        </div>
                        <p className="mb-5">
                            You Drive Practice Dashboard is the pulse of your business, giving you powerful insights into your business, from anywhere and at any time.
                        </p>
                    </Col>
                    <Col md={4}>
                        <div className="d-flex align-items-center pb-4 mt-5">
                            <img src={how6} />

                            <h2 className="text-secondary h4 font-weight-bold pl-3">
                                REAL <br />SAVINGS
                            </h2>
                        </div>
                        <p className="mb-5">
                            NO upfront costs for software license fees or special hardware – our subscription based payment structure allows you take advantage of our competitive rates with monthly, quarterly or annual payment plans.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container fluid style={{ background: "#151445" }}>
                <Row>
                    <Col className="pt-5 mt-5">
                        <Container className="pt-5 mt-5">
                            <Row className="justify-content-center">
                                <Col md={6}>
                                    <h1 className="display-4 text-primary text-center">
                                        Let’s get you started with Drive Revenue
                                    </h1>

                                    <p className="lead text-white text-center pt-5">
                                        Find a plan that suits your firm.
                                    </p>
                                </Col>
                            </Row>

                            <Row className="pt-5">
                                <Col>
                                    <CardDeck>
                                        {priceCards}
                                    </CardDeck>

                                    <small className="d-block text-center pt-5 text-muted">
                                        * Prices quoted excluding applicable taxes
                                    </small>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Container fluid style={{ background: "#fff", marginTop: -200, paddingTop: 300 }}>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col className="mb-5">
                                    <h1 className="display-3 text-primary text-center">
                                        Contact us if your practice is ready for the next level
                                    </h1>
                                    <p className="text-center pt-5 lead">
                                        Contact us today or request a demo.
                                    </p>
                                    <p className="text-center pt-5 pb-5 mb-5">
                                        <RequestADemo>
                                            {onClick =>
                                                <Button className="text-uppercase rounded-pill p-2 pl-4 pr-4" color="primary" size="lg" onClick={onClick}>
                                                    Request a demo
                                                </Button>
                                            }
                                        </RequestADemo>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

        </Layout>
    );
};

export default OurSolutions;
